
<template>
    <div>
        <div id="meet"/>
        <v-row justify="center">
            <v-overlay
                :z-index="zIndex"
                :value="this.overlay"
            >
                <div class="shake">
                  Es klingelt...
                </div>
                <v-btn
                  class="white--text"
                  color="red"
                  @click="CancelCall"
                >
                  Auflegen
                </v-btn>
            </v-overlay>
        </v-row>
    </div>
</template>

<script>

import { mapGetters } from 'vuex'
import { VideoCallStatus } from './enums/VideoCallStatus'
import { Firestore } from '../../src/firebase'

export default {
  name: 'VideoCallComponent',
  data: () => ({
    overlay: true,
    zIndex: 50,
    jitsi: null
  }),
  mounted () {
    const jitsiScript = document.createElement('script')
    jitsiScript.setAttribute('src', 'https://meet.jit.si/external_api.js')
    document.head.appendChild(jitsiScript)
    this.CreateVideocall()
  },
  computed: {
    ...mapGetters({
      user: 'user',
      family: 'family'
    })
  },
  methods: {
    JoinRoom (videoCallId) {
      const domain = 'meet.jit.si'
      const options = {
        roomName: videoCallId,
        width: window.innerWidth,
        height: window.innerHeight - 64,
        parentNode: document.querySelector('#meet'),
        userInfo: {
          displayName: this.user.data.displayName
        },
        configOverwrite: {
          prejoinPageEnabled: false,
          disableDeepLinking: true,
          toolbarButtons: [
          ]
        }
      }
      // eslint-disable-next-line no-undef
      const jitsi = new JitsiMeetExternalAPI(domain, options)
      this.$store.commit('SET_JITSI', jitsi)
    },
    CreateVideocall () {
      if (this.family.data.id) {
        // Create document for call. Check if there is a ongoing call before
        this.IsRemoteCallRunning(this.family.data.id, this.user.data.uid).then((remoteCallRunning) => {
          console.log('remoteCallRunning: ' + remoteCallRunning)
          if (remoteCallRunning) {
            // go to main view
            this.$router.push('/callbusy')
            return
          }
          const videoCallCollection = Firestore.collection('videocalls')
          const timeString = new Date().getTime().toString()
          videoCallCollection
            .add({
              familyId: this.family.data.id,
              callerId: this.user.data.uid,
              avatarName: this.user.data.displayName,
              photoURL: this.user.data.photoURL,
              receiverId: 'Tablet',
              status: VideoCallStatus.Calling,
              startTime: new Date(),
              endTime: '',
              videoCallId: timeString + '#' + this.family.data.id + '#' + this.user.data.uid
            })
            .then(docRef => {
              // TODO: SET vuex store and set currentcallingId in firebase family
              this.$store.commit('SET_LOCALVIDEOCALL_RUNNING', true) // Set local state
              console.log('Calling Tablet! Waiting for answer...')
              docRef.onSnapshot(doc => {
                if (doc.data().status === VideoCallStatus.Accepted) {
                  this.overlay = false
                  this.JoinRoom(doc.data().videoCallId)
                }
              })
            })
            .catch((error) => {
              console.error('Error writing document: ', error)
            })
        })
      }
    },
    CancelCall () {
      // Change document state to "ENDED" for current videocall doc
      if (this.family.data.id) {
        this.$store.commit('SET_LOCALVIDEOCALL_RUNNING', false) // Set local state
        console.log('VideoCallRunning: ' + this.$store.state.family.localVideoCallRunning)
        const videoCallCollection = Firestore.collection('videocalls')
        videoCallCollection
          .where('familyId', '==', this.family.data.id)
          .where('callerId', '==', this.user.data.uid)
          .where('status', 'in', [VideoCallStatus.Calling, VideoCallStatus.Accepted])
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach(doc => {
              doc.ref.update({ status: VideoCallStatus.Hungup, endTime: new Date() })
            })
            const jitsi = this.family.jitsi
            if (jitsi !== null) {
              jitsi.dispose()
              console.log('Videocall disposed')
            }
            // go to call ended view
            this.$router.push('/callended')
          })
      }
    },
    IsRemoteCallRunning: async (familyId, userId) => {
      let remoteCallRunning
      const videoCallCollection = Firestore.collection('videocalls')
      await videoCallCollection
        .where('familyId', '==', familyId)
        .where('callerId', '!=', userId)
        .where('status', 'in', [VideoCallStatus.Calling, VideoCallStatus.Accepted])
        .get()
        .then((querySnapshot) => {
          console.log(querySnapshot)
          if (querySnapshot.size > 0) {
            console.log('There is a remote call running')
            remoteCallRunning = true
          } else {
            console.log('There are NO remote calls running')
            remoteCallRunning = false
          }
        })
      return remoteCallRunning
    }
  }
}
</script>

<style>
  .shake {
    animation-delay: 1s;
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97);
    animation-iteration-count: infinite;
    transform: translate3d(0, 0, 0);
    }
    @keyframes shake {
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }
    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }
    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }
    40%, 60% {
        transform: translate3d(4px, 0, 0);
    }
  }
</style>
